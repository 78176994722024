import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const modal = document.querySelector('#home-modal');
let player;

function hideModal() {
  if (player && player.pauseVideo) {
    player.pauseVideo();
  }
  const tl = gsap.timeline();
  tl.to(modal, {
    opacity: 0,
    duration: 0.1,
    ease: 'none',
  }).set(modal, {left: 'calc(-100% - 100vw)'});
}

function showModal() {
  video();
  gsap.set(modal, {left: 0});
  gsap.fromTo(modal, {
    opacity: 0,
  }, {
    opacity: 1,
    duration: 0.2,
    immediateRender: false
  });
}

function newYouTubePlayer(url) {
  const videoId = url.split('be/')[1];
  player = new YT.Player('home-video', {
    height: '390',
    width: '640',
    videoId: videoId,
    playerVars: {
      'playsinline': 1
    },
    events: {
      'onReady': onPlayerReady,
    }
  });
}

function initYouTubeIframeAPI(url) {
  window.onYouTubeIframeAPIReady = function() {
    newYouTubePlayer(url);
  }
}
// Not ideal but have to make it global to the window for it to work with modules
function onPlayerReady(event) {
  event.target.playVideo();
}

function pauseVideo() {
  player.pauseVideo()
}

function youtubeVideo(url) {
  if (player) {
    player.playVideo();
    return;
  }
  if (window.YT) {
    newYouTubePlayer(url);
    return;
  }
  initYouTubeIframeAPI(url);
  // This code loads the IFrame Player API code asynchronously.
  const tag = document.createElement('script');
  tag.src = "https://www.youtube.com/iframe_api";
  tag.id = 'youtube-script';
  const globalScripts = document.getElementById('global-scripts');
  globalScripts.prepend(tag);
  // Then calls global onYouTubeIframeAPIReady
}

function video() {
  // Check home video element is not on page
  const el = document.querySelector('#home-video');
  if (!el) {
    return;
  }
  const url = el.dataset.url;
  if (url.includes('youtube') || url.includes('youtu.be')) {
    youtubeVideo(url);
  } else if (url.includes('vimeo')) {

  }
}

function animateSVG() {
  const svg = document.querySelector('#homepage-svg');
  if (!svg) {
    return;
  }
  const idTags = Array.from(svg.querySelectorAll('#id-tags > *'));
  const pins = Array.from(svg.querySelectorAll('#pins > *'));
  const people = Array.from(svg.querySelectorAll('#people > *'));
  const trailLines = Array.from(svg.querySelectorAll('#trail-lines path'));
  const masterTl = gsap.timeline();
  masterTl.delay(1).call(() => {
    svg.classList.remove('world-is-hidden')
  }).add('start');

  masterTl.from(pins, {
    y: -20,
    transformOrigin: 'center center',
    duration: 0.5,
    stagger: 0.1,
    ease: 'bounce.out',
  }, 'start');

  masterTl.from(pins, {
    opacity: 0,
    duration: 0.5,
    stagger: 0.1
  }, 'start');

  masterTl.from(people, {
    scale: 0,
    opacity: 0,
    transformOrigin: 'center center',
    duration: 0.2,
    stagger: 0.1,
    ease: 'back.out(1.7)',
  }, 'start');

  idTags.forEach(tag => {
    const tl = gsap.timeline();
    tl.from(tag, {
      y: 20,
      opacity: 0,
      duration: 0.5,
      ease: 'power2.out',
    });
    masterTl.add(tl, "<10%");
  });

  masterTl.add(() => {
    trailLines.forEach(path => {
      const l = path.getTotalLength();
      gsap.fromTo(path, {
        strokeDashoffset: l,
      }, {
        strokeDashoffset:0,
        repeat: -1,
        duration: 30,
        ease: 'none'
      });
    })
  }, '<25%');

  masterTl.from(trailLines, {
    opacity: 0,
    duration: 1,
  }, '<50%');
}

function init() {
  animateSVG();
  const btn = document.querySelector('#home-modal-button');
  const closeBtn = document.querySelector('#home-modal-close');
  if (!modal) {
    return;
  }
  btn.addEventListener('click', showModal);
  closeBtn.addEventListener('click', hideModal);
}

init();
